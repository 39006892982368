<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" href="home">
            <img src="assets/images/logo2-blanco.png" alt="" class="logo-ligh" height="80">
            <img src="assets/images/logo2-color.png" alt="" class="logo-dark" height="80">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" 
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
                    <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Inicio</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
                    <a [ngxScrollTo]="'#services'" href="javascript: void(0)" class="nav-link" [ngxScrollToOffset]="2">Servicios</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'about'}">
                    <a [ngxScrollTo]="'#about'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">Conócenos</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
                    <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">Escógenos</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
                    <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">Clientes</a>
                </li>
            </ul>
            <div class="call-no">
                <a href="#" class="nav-link text-warning"><i class="mdi mdi-phone mr-1"></i> +(504) 97766955</a>
            </div>            
        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
<!-- Hero Start -->
<section class="hero-2-bg position-relative d-flex align-items-center bg-gradient-primary" id="home">
    <div class="hero-2-bg-overlay"></div>
    <div class="container">
        <div class="hero-2-content">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-2-title pr-lg-5">
                        <h1 class="hero-2-title text-white mb-4 font-weight-normal line-height-1_4">Nuestro propósito es convertirnos en participes de cada escalón y momento trascendente en tu empresa y emprendimiento</h1>
                        <p class="text-white-70 font-weight-light f-18 line-height-1_6 mb-5 ">Te acompañamos, aconsejamos, enseñamos, solucionamos e impulsamos tu crecimiento.</p>
                        <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="btn btn-warning mr-lg-2"><i class="mdi mdi-link-variant mr-2"></i> Contáctanos</a>
                        <!--<a href="" class="btn btn-light">Learn More</a>-->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="assets/images/portada.png" alt="" class="img-fluid d-block mx-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-bottom-img">
        <img src="assets/images/" alt="" class="img-fluid d-block w-100 mx-auto">
    </div>
</section>
<!-- Hero End -->
    <app-services></app-services>
    <app-about></app-about>
    <app-features></app-features>
    <app-clients></app-clients>
    <app-contact></app-contact>
    <app-footer></app-footer>
</div>