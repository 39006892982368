<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Nuestros <span class="text-warning">Servicios</span></h3>
                    <p class="text-muted">Generador de soluciones jurídicas para que el día de mañana tu empresa, emprendimiento, sueño, se potencialice a través de la prevención, enseñanza, impulso digital y soluciones adecuadas de manera objetiva.</p>
                </div>
            </div>
        </div>

<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark"><span class="text-warning">Corporativo</span></h3>
                    <p class="text-muted"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-hammer service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">PROPIEDAD INDUSTRIAL E INTELECTUAL</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría integral en trámites legales ante el Instituto Mexicano de la Propiedad industrial, así como el Instituto Nacional del Derecho de Autor.</p>
                    <a href="#" class="read-more-icon"><span class="     ">      </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-tools service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">DUE DILIGENCIE EMPRESARIAL</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en materia de buenas prácticas empresariales o bien, también conocido como Due diligencie empresarial.</p>
                    <a href="#" class="read-more-icon"><span class="      ">       </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-display1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">GOBIERNO CORPORATIVO EN EMPRESAS FAMILIARES</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en materia de mejores prácticas corporativas, a través de nuestro programa de Gobierno Corporativo en Empresas Familiares.</p>
                    <a href="#" class="read-more-icon"><span class="       ">       </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-network service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">FRANQUICIAS</h5>
                    <p class="text-muted service-subtitle mb-4">Redacción de contratos de franquicias, así como de toda la documentación jurídica necesaria, no solo en el marco de las                relaciones.</p>
                    <a href="#" class="read-more-icon"><span class="       ">     </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-display1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">DERECHO CORPORATIVO Y DE NEGOCIOS</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en trámites legales sobre negocios, derecho de la empresa, así como derecho corporativo.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">     </span></a>
                </div>
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!-- Service End -->
<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark"><span class="text-warning">Notarial</span></h3>
                    <p class="text-muted"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-hammer service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">FE PÚBLICA</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en trámites legales notariales y registrales.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">       </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-notebook service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">VALUACIÓN</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en trámites legales sobre valuación inmobiliaria, mobiliaria, activos, etc.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">      </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-map service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">PLANEACIÓN PATRIMONIAL</h5>
                    <p class="text-muted service-subtitle mb-4">Contamos con una amplia gama de soluciones en planeación patrimonial e inversiones en materia inmobiliaria.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">      </span></a>
                </div>
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-cup service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Goal Business</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--   </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Branding Identity</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--   </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!-- Service End -->
<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark"><span class="text-warning">Mercantil</span></h3>
                    <p class="text-muted"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-mail-open-file service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">ARBITRAJE COMERCIAL</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en materia de Arbitraje Comercial.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">     </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-note service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">BANCARIO Y FINANCIERO</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en planeación y trámites ante las diversas instituciones bancarias.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">     </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-global service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">COMERCIO EXTERIOR</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en materia de exportaciones e importaciones, así como negociaciones relativas al comercio exterior y aduanas.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">       </span></a>
                </div>
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-cup service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Goal Business</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Branding Identity</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
            </div>
        </div>
    </div>
</section>
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark"><span class="text-warning">Laboral</span></h3>
                    <p class="text-muted"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-hammer service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">ASESORÍA LABORAL PREVENTIVA Y LITIGIO</h5>
                    <p class="text-muted service-subtitle mb-4">En esta área la firma proporciona servicios legales a patrones o empresas nacionales e internacionales, con diversas actividades empresariales.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">        </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-chat service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">MEDIACIÓN</h5>
                    <p class="text-muted service-subtitle mb-4">Contamos con personal calificado para desempeñar la labor de mediación, uno de los métodos alternos de solución de conflictos, mismo que busca facilitar la comunicación entre las partes.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">       </span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-phone service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">TELECOMUNICACIONES, MEDIOS Y TECNOLOGÍA</h5>
                    <p class="text-muted service-subtitle mb-4">Asesoría en materia de telecomunicaciones, medios y tecnología.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">       </span></a>
                </div>
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-cup service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Goal Business</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Branding Identity</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
        <!--    </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam
                        libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!-- Service End -->
