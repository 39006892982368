<!-- footer Start -->
<section class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="mb-4">
                    <a href="home"><img src="assets/images/logo2-color.png" alt="" class="logo-dark" height="150" /></a>
                    <p class="text-muted mt-4 mb-2"><a href="mailto:informes@inteligencialegalhn.com"></a>informes@inteligencialegalhn.com</p>
                    <h6 class="text-muted font-weight-normal">+(504) 97766955</h6>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">Servicios</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="">Corporativo</a></li>
                            <li><a href="">Notarial</a></li>
                            <li><a href="">Mercantil</a></li>
                            <li><a href="">Laboral</a></li>
                          </ul>
                    </div>
                    <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">Conócenos</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="">Escógenos</a></li>
                            <li><a href="">Nuestros Clientes</a></li>
                            <li><a href="">Contáctanos</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">Nuestras Oficinas</h6>
                        <p class="text-muted f-14">Santa Rosa de Copán, barrio Santa Teresa, edificio OCCIPAT segunda planta cubículo # 1</p>
                        <p class="text-muted f-14">San Marcos Ocotepeque, Barrio San Juan, Edificio Mas Por Menos</p>
                        <h6 class="text-muted pb-2"><a href="mailto:informes@inteligencialegalhn.com"></a>Email: informes@inteligencialegalhn.com</h6>
                        <ul class="list-unstyled footer-social-list mt-4">
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-facebook"></i></a></li>
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="text-center text-muted">
                    <p class="mb-0 f-15"> {{currentYear}} © Albatros Services</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- footer End -->