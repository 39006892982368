<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" href="layout-5">
            <img src="assets/images/logo-dark.png" alt="" class="" height="21">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" 
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
                    <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
                    <a [ngxScrollTo]="'#services'" href="javascript: void(0)" class="nav-link" [ngxScrollToOffset]="2">Services</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'about'}">
                    <a [ngxScrollTo]="'#about'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">About</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
                    <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">Features</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
                    <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link" [ngxScrollToOffset]="2">Clients</a>
                </li>
            </ul>
            <div class="call-no">
                <a href="#" class="nav-link text-warning"><i class="mdi mdi-phone mr-1"></i> +00 1234 5678 90</a>
            </div>            
        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
<!-- Hero Start -->
<section class="hero-4-bg position-relative d-flex align-items-center bg-light" id="home">
    <div class="hero-4-bg-overlay"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <h1 class="text-dark hero-4-title font-weight-light line-height-1_4 mb-4">Grow With Your Business & Be <span class="font-weight-normal">The Best Entrepreneur.</span></h1>
                <p class="text-muted">At vero eos et accusamus et iusto odio dignissimos ducimus a qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint cupiditate.</p>
                <button class="btn btn-warning mt-4">Learn More <span class="ml-2 right-icon">&#8594;</span></button>
            </div>
            <div class="col-lg-4 offset-lg-2">
                <div class="hero-login-form mx-auto bg-white p-4 rounded mt-5 mt-lg-0">
                    <div class="text-center">
                        <p class="text-muted mb-2 f-13 text-uppercase">Welcome To Deoxa</p>
                        <h5 class="form-dark mb-4">Get 30 Days Free Trial</h5>
                    </div>
                    <form class="registration-form">
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="f-15">Your Name*</label>
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
                        </div>
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="f-15">Your email*</label>
                            <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="">
                        </div>
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="f-15">Password*</label>
                            <input type="password" class="form-control" id="exampleFormControlInput3" placeholder="">
                        </div>
                        <button type="submit" class="btn btn-warning btn-block btn-sm">Get Started <i class="mdi mdi-telegram ml-2"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero End -->
    <app-services></app-services>
    <app-about></app-about>
    <app-features></app-features>
    <app-clients></app-clients>
    <app-contact></app-contact>
    <app-footer></app-footer>
</div>