<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Excelente</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <p><strong>Are you sure you want to delete <span class="text-primary">"John Doe"</span> profile?</strong></p>
    <p>All information associated to this user profile will be permanently deleted.
        <span class="text-danger">This operation can not be undone.</span>
    </p> -->
    <p>Gracias por contactarte con nosotros, lo revisaremos en la brevedad posible.</p>
</div>

<div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Aceptar</button>
</div>