<!-- Contact Us Start -->
<section class="section" id="contact">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">A solo <span class="text-warning"> un clic</span></h3>
                    <p class="text-muted"> </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="contact-address">
                    <h4 class="text-dark mb-4">Información de Contacto</h4>
                    <p class="text-muted f-15">Dejanos tus datos y a la brevedad nos estaremos contactando usted.</p>
                    <p class="text-muted f-15 mb-4"> </p>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Dirección - A</h5>
                            <p class="text-muted f-15">Santa Rosa de Copán, barrio Santa Teresa, frente a kinder
                                Federico Canales, edificio OCCIPAT segunda planta cubículo # 1</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Dirección - B</h5>
                            <p class="text-muted f-15">San Marcos Ocotepeque, Barrio San Juan, Edificio Mas Por Menos
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Teléfono</h5>
                            <p class="text-muted f-15">+(504)26634399</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Celular</h5>
                            <p class="text-muted f-15">+(504)97766955</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 offset-lg-1">
                <div class="custom-form mt-4 mt-lg-0">
                    <div id="message"></div>
                    <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="name">Nombre</label>
                                    <input [(ngModel)]="form.name" [ngModelOptions]="{standalone: true}" name="name"
                                        id="name" type="text" class="form-control" placeholder="Coloca tu nombre..">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="email">Correo Electrónico</label>
                                    <input [(ngModel)]="form.email" [ngModelOptions]="{standalone: true}" name="email"
                                        id="email" type="email" class="form-control" placeholder="Coloca tu correo..">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group app-label">
                                    <label for="subject">Asunto</label>
                                    <input [(ngModel)]="form.subject" [ngModelOptions]="{standalone: true}" type="text"
                                        class="form-control" id="subject"
                                        placeholder="Coloca el motivo de tu consulta.." />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group app-label">
                                    <label for="comments">Mensaje</label>
                                    <textarea [(ngModel)]="form.message" [ngModelOptions]="{standalone: true}"
                                        name="comments" id="comments" rows="3" class="form-control"
                                        placeholder="Coloca un mensaje mas detallado.."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button type="submit" id="submit" name="send" class="btn btn-warning"
                                    (click)="send()">Enviar Mensaje <i class="mdi mdi-telegram ml-2"></i></button>
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact Us End -->

<!-- Subscribe Start -->
<!--<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-4">
                        <div class="subscribe-icon">
                            <img src="assets/images/icon/1.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="subscribe-icon">
                            <img src="assets/images/icon/2.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="">
                            <img src="assets/images/icon/3.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mt-5 mb-4">
                    <h6 class="text-muted font-weight-normal">Subscribe To Our Newsletter For New Content, <span class="d-block mt-2">Update And Giveaways!</span></h6>
                </div>
                <div class="text-center subscribe-form mt-4">
                    <form action="#">
                        <input type="text" placeholder="Your Email Address...">
                        <button type="submit" class="btn btn-warning">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- Subscribe End -->