<!-- Testimonial Start -->
<section class="section bg-light" id="clients">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="title text-center mb-5">
            <h3 class="font-weight-normal text-dark">Nuestros <span class="text-warning">Clientes</span></h3>
            <p class="text-muted">    </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <h3 class="font-weight-normal mt-3 line-height-1_4">Algunas palabras de<span
              class="font-weight-medium text-warning"> nuestros Clientes</span></h3>
          <div class="testi-border my-4"></div>
          <p class="text-muted">Nos importa mucho la opinión de nuestros clientes.</p>
        </div>
        <div class="col-lg-8 testi-carousel">
          <owl-carousel-o [options]="testimonialOptions">
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Me explicaron todo de manera fácil y entendible. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Estephanie Guevara</h6>
                        <p class="text-muted mb-0 f-14">Valuación Inmobiliaria</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Me ayudaron a conciliar con mi empleador y me pagarón lo que me debían. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-6.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Francisco Melgar</h6>
                        <p class="text-muted mb-0 f-14">Problemas con Empleador</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Me asesoraron para poder importar artículos electrónicos y no tener problemas con las leyes aduaneras. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-7.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Carlos Rodezno</h6>
                        <p class="text-muted mb-0 f-14">Importación de Bienes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Me ayudaron con mi proyecto de abrir mi negocio de una franquicia. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-8.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberto Mejia</h6>
                        <p class="text-muted mb-0 f-14">Emprendedor</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        <!--</ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonial End -->
  
  