<!-- About us Start -->
<section class="section bg-light" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Conóc<span class="text-warning">emos</span></h3>
                    <p class="text-muted">las necesidades de nuestros clientes estableciendo una relación especial y única con cada uno de ellos, buscamos involucrarnos profundamente en los asuntos encomendados para ofrecer soluciones de vanguardia a través de
                    transparencia, honestidad, dedicación y profesionalismo.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <h2 class="font-weight-light line-height-1_6 text-dark mb-4">Apasionados en ayudar a la creación de empresas</h2>
            </div>
            <div class="col-md-7 offset-md-1">
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-dark font-weight-light f-20 mb-3">Nuestra Misión</h6>
                        <p class="text-muted font-weight-light">Prestar un servicio jurídico altamente especializado con visión de negocio, ofreciendo soluciones de vanguardia para ser aliados en el éxito de las empresas.</p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-dark font-weight-light f-20 mb-3">Nuestra Visión</h6>
                        <p class="text-muted font-weight-light">Ser una firma líder que aspira a la excelencia en la prestación de servicios legales. Nos enfocamos a implementar
                        estrategias innovadoras para proveer de seguridad, confianza y tranquilidad a nuestros clientes y podernos convertir en su socio estratégico de negocios.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/caracteristicas4.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">LUIS ALONSO AGUILAR<br>SOCIO</h5>
                    <p class="text-muted mb-3 f-12">Luis Alonso Aguilar es socio en Bufete Aguilar Colindrez & Asoc Con 28 años de experiencia, es experto de la práctica
                    notarial, litigios y arbitraje, en materias civiles, mercantiles y administrativas, tanto en jurisdicción ordinaria y
                    arbitral, con especial énfasis en litigios relacionados con contratos de: construcción de obra pública y privada,
                    inversión, asociaciones público-privadas, seguros, servicios profesionales, telecomunicaciones y de representación,
                    distribución y agencia.
                    <br>
                    <br>Educación:
                    <br>Licenciado en Derecho con Orientación en Derecho Penal y Mercantil, Universidad Nacional Autónoma de Honduras UNAH,
                    Honduras.
                    </p>
                    <a href="#" class="f-12 text-warning">        <span class="   "></span></a>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">LUIS FABRICIO AGUILAR</h5>
                    <p class="text-muted mb-3 f-12">Luis Fabricio Aguilar es socio en Aguilar Colindrez & Asoc con experiencia en los sectores bancario, financieros y
                    corporativo, se especializa igualmente en análisis económico del derecho con orientación en finanzas corporativas
                    cursando su maestría en la Pontifica Universidad Catolica de Chille la Maestria en Asesoria Juridica de Empresas (LLM)
                    Sus áreas de práctica incluyen también derecho comercial, derecho de seguros, agencia y distribución, derecho de
                    franquicias, marcas, patentes, derecho de autor, competencia económica, litigio civil y comercial, arbitraje comercial y
                    derecho administrativo. Es árbitro comercial nombrado por el Honorable Colegio de Abogados. A lo largo de su carrera ha
                    sido el abogado de contacto para empresas locales y extranjeras, bancos e instituciones financieras.
                    <br>
                    <br>Educación:
                    <br>Licenciatura en Derecho con Orientación Empresarial, Universidad Tecnológica Centroamericana (UNITEC), Honduras
                    (2017)
                    <br>Cursando Maestria en Aseosria Juridica de Empresas Pontifica Universidad Catolica de Chile (LLM).</p>
                    <a href="#" class="f-12 text-warning">      <span class="     "></span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/caracteristicas5.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
        </div>
    </div>
</section>
<!-- About us End -->