<!-- Features Start -->
<section class="section" id="features">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Porque <span class="text-warning">Escogernos</span></h3>
                    <p class="text-muted"></p>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/caracteristicas1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Dedicación Exclusiva</h5>
                    <p class="text-muted mb-3 f-15">Atención personalizada para cada uno de nuestros cliente. Les hacemos entender que ellos son únicos y le brindamos la atención personalizada que ellos necesitan.</p>
                    <a href="#" class="f-16 text-warning">     <span class="right-icon ml-2">      </span></a>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Especialización</h5>
                    <p class="text-muted mb-3 f-15">Somos el único bufete especializado en el Occidente de Honduras.Contamos con los mejores especialistas del sector para poder satisfacer todas tus dudas.</p>
                    <a href="#" class="f-16 text-warning">    <span class="right-icon ml-2">     </span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/caracteristicas2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

    <!--<div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/caracteristicas3.png" alt="" class="img-fluid d-block mx-auto">
                </div>
             </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                  </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Marketing Analysis</h5>
                    <p class="text-muted mb-3 f-15">At vero eos accusamus iusto odio soluta nobis est eligendi optio dignissimos ducimus qui blanditiis praesentium as voluptatum deleniti corrupti quos dolores molestias occaecati.</p>
                    <a href="#" class="f-16 text-warning">Read More <span class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>-->
    </div>
</section>
<!-- Features End -->

<!-- Counter Start -->
<section class="section counter-bg" style="background: url(assets/images/counter-bg-1.jpg) center center">
    <div class="bg-overlay op-75"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mb-5">
                    <h2 class="font-weight-normal text-white mb-2">Contamos</h2>
                    <h5 class="font-weight-normal text-white-70">  </h5>
                </div>
            </div>
        </div>
        <div class="row" id="counter">
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-like2 counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="500" [from]="0" [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Clientes Satisfechos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-light counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="50" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Proyectos en Proceso</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-smile counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="50" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Clientes Nuevos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-star counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="30" [from]="0"
                        [duration]="1"></span></h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Procesos recien concluidos</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Counter End -->